import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import Layout from "../layouts/en"
import PostLink from "../components/post-link"
import HeroHeader from "../components/heroHeader"

const IndexEnPage = ({
  data: {
    site,
    allMarkdownRemark: { edges },
  },
  location,
}) => {
  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />)

  return (
    <Layout location={location}>
      <Helmet>
        <title>{site.siteMetadata.title}</title>
        <meta name="description" content={site.siteMetadata.description} />
      </Helmet>
      <HeroHeader />
      <h2 className="text-3xl mb-4">Posts &darr;</h2>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
        {Posts}
      </div>
    </Layout>
  )
}

export default IndexEnPage
export const pageQuery = graphql`
  query indexEnPageQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { type: { ne: "Static" }, langKey: { eq: "en" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "DD/MM/YYYY")
            path
            title
            type
            diff
            thumbnail
          }
        }
      }
    }
  }
`
